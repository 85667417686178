<template>
  <base-page
    :title="$t('terminalActiveCode.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.terminalFindActivationCode"
      :columns="columns"
      :multiple="true"
      :highlightCurrentRow="false"
      rowName="code"
      v-model="tableRows"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 有效期至 -->
      <template v-slot:expirationDate="{ row }">
        {{ $util.format(row.expirationDate, 'yyyy-MM-dd') }}
      </template>
    </table-list>
    <detail v-if="showDetail" @closePage="closePage" />
  </base-page>
</template>

<script>
import Detail from './detail.vue'
import { importFile } from '@/utils/ajax'

export default {
  components: {
    Detail,
  },
  data() {
    const self = this
    return {
      // 搜索
      formData: {
        enableVale: 'NORMAL'
      },
      config: [
        {
          tag: 'el-select',
          label: this.$t('terminalActiveCode.companyId'),
          prop: 'companyUid',
          span: 7,
          tagProps: {
            options: [],
          },
        },
        {
          tag: 'el-select',
          label: this.$t('base.enable'),
          prop: 'enableVale',
          span: 5,
          tagProps: {
            options: this.$util.copyData(this.$dict.enable()),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表单页
      showDetail: false,
      // 表格页
      tableRows: [],
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:设备码激活码
        {
          label: this.$t('terminalActiveCode.code'),
          prop: 'code',
        },
        // lang:关联企业
        {
          label: this.$t('terminalActiveCode.companyId'),
          prop: 'companyUid',
          callback(row) {
            return row.company && row.company.name
          },
        },
        // lang:关联功能
        {
          label: this.$t('terminalActiveCode.functionId'),
          prop: 'functionType',
          callback(row) {
            if(self.$util.getLocalStorage('apiprefix') === 'workFlow') {
              return self.$t('deviceEnum.' + row.functionType)
            } else {
              return row.function && row.function.name
            }
          },
        },
        // lang:有效期至
        {
          label: this.$t('terminalActiveCode.expirationDate'),
          prop: 'expirationDate',
        },
        // lang:状态
        {
          label: this.$t('base.enable'),
          prop: 'enable',
          callback(row) {
            return self.$util.listToObj(self.$dict.enable())[row.enable]
          },
        },
        // lang:是否已激活使用
        {
          label: this.$t('terminalActiveCode.isActivated'),
          prop: 'isActivated',
          callback(row) {
            return self.$util.listToObj(self.$dict.yesOrNo())[row.isActivated]
          },
        },
      ],
    }
  },
  mounted () {
   this.companyFind()
  },
  methods: {
    // 获取企业列表
    companyFind() {
      this.$ajax({
        url: this.$api.companyFind,
        data: {
          productType : this.$util.getLocalStorage('productType')
        }
      }).then((data) => {
        const companyList = data.map((item) => ({
          label: item.name,
          value: item.uid,
        }))
        companyList.unshift({
          label: this.$t('terminalActiveCode.noCompanyId'),
          value: -1,
        })
        this.config[0].tagProps.options = companyList
      })
    },
    // 关闭详情页
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && !this.tableRows.length) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
          this.showDetail = true
          break
        case 'disable':
          this.$element
            .showConfirm({
              content: this.$t('base.disableConfirm'),
            })
            .then(() => {
              this.terminalChangeActivationCode()
            })
          break
        case 'export':
          this.exportMethod()
          break
        default: // do something
      }
    },
    // 删除
    terminalChangeActivationCode() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.terminalChangeActivationCode,
        data: {
          ids: this.tableRows.map((item) => item.id),
          enable: 'DISABLE',
        },
      }).then(() => {
        // lang:禁用成功
        this.$element.showMsg(this.$t('base.disableSuccess'), 'success')
        this.search()
      })
    },
    // 导出
    exportMethod() {
      const params = {
        qrcodeSize: 170,
        logoSize: 10,
        fontWidth: 12,
        clo: 3,
        pageCount: 3,
      }
      params.codeId = this.tableRows.map((item) => item.id).join(',')
      importFile({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.terminalExportActivationCode,
        // lang:设备激活码二维码
        name: this.$t('terminalActiveCode.activationCode'),
        type: '.docx',
        data: params,
      })
    },
  },
}
</script>
