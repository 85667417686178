<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
      @openDialog="openDialog"
    />
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      :defaultParams="dialogDefaultParams"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
    </dialog-table-list>
  </div>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const cmd = this.$store.state.cmd
    return {
      cmd,
      formData: {},
      config: [
        // lang:激活码数量
        {
          label: this.$t('terminalActiveCode.num'),
          prop: 'num',
          rules: {
            noNull: true,
            type: 'positiveInt',
          },
        },
        // lang:关联企业
        {
          tag: 'dialog-define',
          label: this.$t('terminalActiveCode.companyId'),
          prop: 'companyUid',
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        },
        // lang:关联功能
        {
          tag: 'el-select',
          label: this.$t('terminalActiveCode.functionId'),
          prop: this.$util.getLocalStorage('apiprefix') === 'workFlow' ? 'functionType' : 'functionId',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
          },
        },
        // lang:有效期至
        {
          tag: 'el-date-picker',
          label: this.$t('terminalActiveCode.expirationDate'),
          prop: 'expirationDate',
          rules: {
            noNull: true,
          },
        },
      ],
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: null,
    }
  },
  mounted() {
    this.terminalFindFunction()
  },
  methods: {
    // 查询终端功能
    terminalFindFunction() {
      if(this.$util.getLocalStorage('apiprefix') === 'workFlow') {
        this.config[2].tagProps.options = this.$dict.deviceDict()
        return
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.terminalFindFunction,
      }).then((data) => {
        const list = data || []
        this.config[2].tagProps.options = list.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      })
    },
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      // 选择设备
      this.dialogTitle = this.$t('adminAccount.companyInfo')
      this.dialogUrl = this.$api.companyFind
      this.dialogDefaultParams = {
        productType : this.$util.getLocalStorage('productType')
      }
      this.dialogColumns = [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
        },
        // lang:行业
        {
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          callback(row) {
            return row && row.industry && row.industry.value
          },
        },
        // lang:地址
        {
          label: this.$t('companyManage.address'),
          prop: 'address',
          callback(row) {
            return row && row.address && row.address.value
          },
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
        },
      ]
      this.dialogConfig = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('companyManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ]
      this.showDialogTableList = true
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.tableList.searchList(dialogFormData)
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      console.log('formData', formData)
      // 有效期
      formData.expirationDate = new Date(formData.expirationDate).getTime()
      // 关联企业
      if (formData.companyUid) {
        formData.companyUid = formData.companyUid.uid
      } else {
        delete formData.companyUid
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.terminalAddActivationCode,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
  },
}
</script>
